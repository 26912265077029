.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo-container {
  height: 12vh;
  place-content: center;
  //background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  display: flex;
  // justify-content: flex-start;
  align-content: center;
  .logo-icon {
    height: 100%;
    user-select: none;
  }
}
.logo-container-collapsed {
  //width: 5vw !important;
  height: 5vh !important;
}

.site-layout .site-layout-background {
  background: #fff;
}

.link-color {
  color: #fff !important;
}
.link-color-menu {
  color: #000000 !important;
}

.customSpin {
  max-height: 1080px !important;
}
.teszt {
  background: linear-gradient(135deg, #c3ec52 0%,#0ba29d 100%) !important
}
