.login-page-container {
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // width: 25% !important;
  .loginform {
    width: 100% !important;
  }
}
